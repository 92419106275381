import React from "react";
import "../styles/components/Header.css";
const Header = (props) => {
    return (
        <header scroll={props.initScroll ? "true" : "false"} className={props.block && "header-block"}>
            <a href="/" className="logo">
                <img src={process.env.PUBLIC_URL + "/images/logoHalf.png"} alt="logo" />
                <div className="logo-text">
                    <h1>TE RAU <span style={{fontWeight: 600, color:"var(--primary)"}}>WHIRIA</span></h1>
                    <h5>Bringing together many resources {props.scrolled}</h5>
                </div>
            </a>
            <nav>
                <ul>
                    {/*<li><a href="/">Home</a></li>*/}
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/rohe">Around the Rohe</a></li>
                    <li><a href="/services">Our Services</a></li>
                    <li><a href="/gallery">Gallery</a></li>
                    {/*<li><a href="/contact-us">Download App</a></li>*/}
                </ul>
            </nav>
        </header>
    );
};

const MobileHeader = (props) => {
    return (
        <header className="mobile">
            <a href="/" className="logo mobile">
                <img src={process.env.PUBLIC_URL + "/images/logoHalf.png"} alt="logo" />
                <div className="logo-text">
                    <h1>TE RAU <span style={{fontWeight: 600, color:"var(--primary)"}}>WHIRIA</span></h1>
                </div>
            </a>
        </header>
    );
}

const ExportHeader = () => {
    let init_scroll = false;
    let spacer = true;
    
    if(window.location.pathname === "/") { // If we're on the home page, we want to hide the header
        window.addEventListener("scroll", () => {
            document.querySelector("header").setAttribute("scroll", window.scrollY > 0);
        });
        spacer = false;
    } else {
        init_scroll = true;
    }

    if (window.innerWidth >= 1053) {
        return spacer ? <div style={{height:"5em"}}><Header initScroll={init_scroll} /></div> : <Header initScroll={init_scroll} />;
    } else {
        return <MobileHeader />;
    }
};

export default ExportHeader;