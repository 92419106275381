import React from "react";
import Jumbotron from "../components/Jumbotron";
import SideHeadingText from "../components/SideHeadingText";

class AboutUs extends React.Component {
  render() {
    return (
        <div className="container">
            <Jumbotron title="About Us" src={process.env.PUBLIC_URL + '/images/banner1.jpg'} />
            <div style={{"padding": "10px 0"}}>
                <SideHeadingText subheading="THE" heading="BEGINNING" text={`
                In 2010 the Te Tai Tokerau Whanau Ora Collective was originally made up of five leading Maori health and social service providers in Northland: the Ngati Hine Health Trust, Whakawhiti Ora Pai, Te Runanga O Te Rarawa, Te Hiku Hauora and Ki a Ora Ngatiwai.
                \n\n
                The decision to Collectivise was in response to Tariana Turia's Whanau Ora Task Force Report on Whanau Centred Initiatives and was a natural progression of the continued commitment of TTWOC to further support the advancement of whanau and the communities it served. The early development of the Collective in 2011 was led by the TTTWOC Governance and Executive Group, who drafted a Programme of Action (POA), Model of Care and Kaikokiri Competencies Framework.
                \n\n
                At the conclusion of that year, the POA and Business Case was approved and the first three year POA implementation contract with Te Puni Kokiri began in April 2012.
                `} />
                <SideHeadingText className="color-block" subheading="WHERE TTTWOC IS" heading="NOW" text={`
                Fast forward to 2023, the Te Tai Tokerau Whānau Ora Collective is now made up of 12-multi-sectorial Kaupapa Maori Organisations (KMO) across Te Tai Tokerau.
                \n\n
                TTTWOC delivers an extensive range of integrated services from Ruakaka in the South, to Te Hapua in the Far North. Our Partner providers have bases in the Far North (Kaitaia, Whaingaroa, Te Kao, Te Hapua,) the mid-North (Moerewa, Kawakawa, Kaikohe, Hokianga) and Whangarei region (Whangarei south, central, East Coast, Kamo and Otangarei).
                \n\n
                The collective employs upwards of 800+ staff and contractors across our network and delivers on 60+ service contracts. Our Partners are based in their respective communities which makes them specialists in local knowledge. They are responsive to community need when it arises, which allows for a solutions focused lens to be cast that encourages innovative thinking when it comes to providing local support.`} />
            </div>
        </div>
    )
  }
}

export default AboutUs;