import React from "react";
import "../../styles/Services.css"
import {FiChevronDown} from "react-icons/fi"

import Jumbotron from "../../components/Jumbotron";
import ServiceCategoryItem from "../../components/ServiceCategoryItem";
import SearchInput from "../../components/SearchInput";

import services from "../../TTTWOC_SERVICES.json" // TODO: Change this to an API call.

import { get_service_categories } from "../../hooks/firebase";

const SERVICE_CATEGORY_LIST = [... new Set(services.map(e => e.category))];

// TEMP
//SERVICE_CATEGORY_LIST["EDUCATION"].img = process.env.PUBLIC_URL + "/images/63d999c8c2c093d86503ae70_nhht-services_education_te_mirumiru-p-500.png";

class Services extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            services: services,
            serviceCategories: SERVICE_CATEGORY_LIST
        }
    }

    componentDidMount(){
        this._getCateogries();
    }

    async _getCateogries(){
        const categories = await get_service_categories();
        this.setState({serviceCategories: categories});
        console.log(categories);
    }

    render(){
        return(
            <div>
                <Jumbotron style={{backgroundPosition:"top"}} title="Our Services" src={process.env.PUBLIC_URL + '/images/covid2.jpg'} />
                <div style={{padding: "1em"}}>
                    <SearchInput placeholder="Search Services" />
                </div>
                <div className="services-container">
                    {
                        this.state.serviceCategories.map((category) => {
                            return <ServiceCategoryItem
                                name={category.title}
                                description={category.description}
                                img={category.img ? process.env.PUBLIC_URL + `/images/${category.img}`: process.env.PUBLIC_URL + "/images/covid3.jpg"}
                            />
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Services;