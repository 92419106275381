import React from "react";
import "../styles/Gallery.css"

export default function Gallery() {
    return (
        <div className="gallery-container">
            <div className="gallery-whakatauki">
                <div style={{display:"flex", gap:"2px", overflow:"hidden", width:"100%", justifyContent:"center"}}>
                    {
                        (function(){
                            let triangles = [];
                            for(let i = 0;i < 100; i++){
                                triangles.push(<div className="gallery-triangle"></div>)
                            }
                            return triangles;
                        })()
                    }
                </div>
                <p>Naku te rourou nau te rourou ka ora ai te iwi</p>
                <p>With your basket and my basket the people will live</p>
                <div style={{display:"flex", gap:"2px", overflow:"hidden", width:"100%", justifyContent:"center"}}>
                    {
                        (function(){
                            let triangles = [];
                            for(let i = 0;i < 100; i++){
                                triangles.push(<div className="gallery-triangle flip"></div>)
                            }
                            return triangles;
                        })()
                    }
                </div>
            </div>
            <div className="gallery-content">
                <img src={process.env.PUBLIC_URL + '/images/covid2.jpg'} alt="Whanau" />
                <img src={process.env.PUBLIC_URL + '/images/63d999c8c2c093d86503ae70_nhht-services_education_te_mirumiru-p-500.png'} alt="Whanau" />
                <img src={process.env.PUBLIC_URL + '/images/147067489_1314159045626124_9128375519023880777_n.jpeg'} alt="Whanau" />
                <img src={process.env.PUBLIC_URL + '/images/320915985_1298869360900250_6355585929230402397_n.jpg'} alt="Whanau" />
                <img src={process.env.PUBLIC_URL + '/images/440934363_476616208044059_3809671882065199529_n.jpg'} alt="Whanau" />
                <img src={process.env.PUBLIC_URL + '/images/banner1.jpg'} alt="Whanau" />
                <img src={process.env.PUBLIC_URL + '/images/covid3.jpg'} alt="Whanau" />
            </div>
        </div>
    );
}