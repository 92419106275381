import React from 'react';

// Modules
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ReactGA from 'react-ga4';

// Components
import Footer from './components/Footer';
import Header from './components/Header';

// Background Scripts
import GoogleAnalytics from './components/GoogleAnalytics';
import TitleHandler from './components/TitleHandler';

// Pages
import Index from './pages/Index';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services/Services';
import Error from './pages/Error';
import Rohe from './pages/Rohe';
import Whanau from './pages/Whanau/Whanau';
import Story from './pages/Whanau/Story';

import Gallery from './pages/Gallery';

// Routers
import ServiceRoutes from './pages/Services/Router';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
          <Route title="Home" path="/" element={<Index />} />
          <Route title="About Us" path="/about-us" element={<AboutUs />} />
          <Route title="Services" path="/services" element={<Services />} />
          <Route title="Services" path="/services/*" element={<ServiceRoutes />} />
          <Route title="Rohe" path="/rohe" element={<Rohe />} />
          <Route title="Whanau" path="/whanau">
            <Route title="Whanau" path="" element={<Whanau />} />
            <Route title="Whanau" path="story" element={<Story />} />
          </Route>
          <Route title="Error" path="/gallery" element={<Gallery />} />
          <Route title="Not Found!" path="/*" element={<Error />} />
      </Routes>
      <Footer />
      {/* Background Scripts */}
      <TitleHandler />
      <GoogleAnalytics />
    </Router>
  );
}

export default App;
